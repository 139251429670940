<template>
    <div class="p-9 text-xs">
        <div class="mb-6 -mt-4">
            <button @click="$router.push('/customer')" class="flex items-center text-blue-500">
                <!-- Font Awesome for "chevron-left" -->
                <i class="fas fa-chevron-left w-6 h-6 mb-2"></i>
                <span class="ml-2 mb-5">Kembali</span>
            </button>
            <!-- <button class="text-lg border inline-block px-2 px-4 rounded hover:bg-gray-100" @click="$router.go(-1)"><i class="far fa-arrow-alt-left"></i></button> -->
        </div>
        <div class="rounded shadow bg-white p-4">

            <div class="">
                <div class="w-full -mt-8">
                    <div class="py-2 px-4 text-white shadow-xl biru rounded  inline-block">
                        <p class="inline-block text-sm">{{ $route.name }}</p>

                    </div>
                </div>
                <div class="mt-8">

                </div>

                <div>
                    <div class="mt-4 grid grid-cols-12 gap-4">
                        <!-- <div class="col-span-3">
                           <div class="flex justify-center border border-gray-200">
                               <div class="w-40 h-40 ">
                                   <img :src="default_url + 'default.png'" class="w-40 h-40 object-cover " @click="$refs.foto_profile.click()"/>
                               </div>
                           </div>
                       </div> -->
                        <div class="col-span-12">

                            <div class="mt-4 w-full overflow-x-auto">
                                <button class="text-white shadow rounded text-xs bg-green-500 px-2 py-2"
                                    @click="addDevice()">Tambah Perangkat</button>
                                <table class="table-auto w-full text-xs overflow-x-auto">
                                    <tr class="text-left">
                                        <th class=" text-gray-500 py-2 px-4">Perangkat</th>
                                        <th class=" text-gray-500 py-2 px-4">Tanggal Expired</th>
                                        <th class=" text-gray-500 py-2 px-4">Aksi</th>

                                    </tr>

                                    <tr v-for="(item, index) in data" :key="index"
                                        :class="`cursor-pointer ${index % 2 == 0 ? 'bg-gray-100 ' : ' '} hover:bg-gray-100`">
                                        <td class="text-gray-500 py-2 px-4">
                                            <v-select :options="device" v-model="item.device_id"
                                                :reduce="device => device.id" label="name">
                                                <template v-slot:option="option">

                                                    {{ option.name }} ({{ option.type }})
                                                </template>
                                                <template #selected-option="{ name, type }">
                                                    {{ name }} ({{ type }})
                                                </template>
                                            </v-select>
                                        </td>
                                        <td class="text-gray-500 py-2 px-4">
                                            <input v-model="item.expired_date" type="date"
                                                :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs`"
                                                name="start_date" ref="start_date" id="start_date" placeholder="Nama" />
                                        </td>
                                        <td class="">
                                            <button
                                                class="text-white shadow rounded mr-1 h-8 w-8 text-xs bg-green-500 inline"
                                                @click="saveDevice(item.id, item)">
                                                <i class="far fa-save"></i>
                                            </button>

                                            <button class="text-white shadow rounded h-8 w-8 text-xs bg-red-600"
                                                @click="deleteDevice(item.id, index)">
                                                <i class="far fa-times"></i>
                                            </button>

                                        </td>

                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapAction, mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data: () => ({
        data: [],
        device: [],

        default_url: process.env.VUE_APP_IMAGES
    }),
    mixins: [globalMixin],
    methods: {

        addDevice() {
            this.data.push({
                customer_id: this.$route.query.customer_id,
                device_id: '',
                expired_date: '',
                id: ''
            })
        },
        saveDevice(id, item) {
            if (id != '') {
                this.axios.put('v1/customer_device/' + id, item, this.config)
                    .catch((err) => {
                        console.log(err)
                    })
            } else {
                this.axios.post('v1/customer_device', item, this.config)
                    .catch((err) => {
                        console.log(err)
                    })
            }

            this.$snack.success({
                text: 'Berhasil Simpan Data',

            })
        },
        deleteDevice(id, index) {
            this.data.splice(index, 1)
            if (id) {
                this.axios.delete('v1/customer_device/' + id, this.config)
                    .then((ress) => {
                        console.log(ress)
                    })

            }
        },
        async getData() {


            this.axios.get('v1/customer_device?customer_id=' + this.$route.query.customer_id, this.config)
                .then((ress) => {
                    this.data = ress.data.rows
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                    }
                    this.errorResponse(err)
                })

        },

    },
    computed: {

    },
    created() {
        this.getData()
        this.getDevice()
    },
    components: {
        ValidationProvider, ValidationObserver
    }
}
</script>